.item-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3em;
  cursor: pointer;
  transition: background-color 10s ease-out 100ms;
}
.card:hover .eye-icon {
  opacity: 1;
}
.eye-icon {
  opacity: 0.1;
}
.card {
  text-align: center;
  width: 350px;
  border-radius: 5px;
  padding: 1.5em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.display-candidate-thead {
  background-color: #1f7ebc;
  color: white;
  position: sticky;
  text-align: center;
}

.display-candidate-thead th {
  background-color: #1f7ebc;
  color: white;
}

/* .display-candidate-tr:hover {
  background-color: #f3f3f3;
  color: black;
} */

/* .col{
    border:1px solid black !important;
  } */

.table-parent-div {
  /* padding: 12px; */

  height: 72vh;

  width: 100%;

  overflow-y: scroll;

  overflow-x: hidden;
}

.table-display-candidate {
  position: relative;
}

thead {
  background-color: #1f7ebc;
  color: white;
  position: absolute;
  top: 0px;
  border: 1px solid;
  z-index: 1;
}

.table-display-candidate tbody{
  text-align:center!important;
}