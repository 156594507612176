@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.button {
  font-weight: 500;
  text-align: center;
  font-size: 19px;
  /* color: #fff; */
  background-color: #1f7ebc;
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);

  /* padding: 1rem 2.5rem; */
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;
}

.button:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-4px);
}

.button:active {
  background-color: rgba(66, 63, 63, 0.8);
}
