[type="date"] {
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    97% 50% no-repeat;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* custom styles */
.date-picker-label {
  /* font-family: "Roboto", sans-serif; */
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
}
.date-picker {
  color: hsl(0, 8%, 81%);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  border: none;
  padding: 1em;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid rgb(150, 146, 146);
}
