.no-records-found-error-message {
  margin-top: 20px;
  color: red;
}
.Show-Candidate-Btn {
  color: white;
}
.Show-Candidate-Models {
  width: 40px;
}
.Show-Candidate-Btn-div button {
  width: 80%;
  font-size: 15px;
  height: 35px;
  color: white;
}

.display-candidate-thead th {
  background-color: #1f7ebc;
  color: white;
}

.AdminDashboard-Details-btn {
  color: white;
  padding: 5px;
}

/* .display-candidate-tr:hover {
  background-color: #f3f3f3;
  color: black;
} */

/* .col{
  border:1px solid black !important;
} */

.table-parent-div {
  /* padding: 12px; */

  height: 60vh !important;

  width: 100%;

  overflow-y: scroll;

  overflow-x: hidden;
}
.display-candidate-thead {
  background-color: #1f7ebc;
  color: white;
  position: absolute;
  top: 0px;
  border: 1px solid;
  z-index: 1;
}

.table-display-candidate {
  color: white;
  text-align: center;
  position: relative;
}

.table-display-candidate tbody {
  text-align: center !important;
}
.arrow-icon {
  background-color: #1f7ebc;
  justify-content: center;
  width: 70px;
  color: white;
  height: 30px;
  border-radius: 10px;
  cursor: pointer;
}
.arrow-icon:hover {
  box-shadow: 0px 6px 10px rgba(243, 239, 239, 0.3);
  transform: translateY(-2px);
}
.arrow-icon:active {
  color: black;
}
.add-recruiter-left {
  margin-left: 10px;
}
.add-recruiter-right {
  margin-right: 10px;
}
.add-recruiter-parent {
  display: flex;
  column-gap: 30px;
}
.add-recruiter-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
}
.add-recruiter-container {
  /* height: 380px !important; */
  padding-top: 10px !important;
}

/* @media screen and (max-width:1000px) {
  .table-display-candidate tr {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 2fr));
    grid-gap: 3px;
    margin-bottom: 3px;
  }
}

 @media screen and (max-width:500px) {
  .table-display-candidate tr {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 3px;
    margin-bottom: 3px;
  }
}   */

/* @media screen  and (min-width:400px) and  (max-width: 820px) {
     .table-parent-div{
        overflow-x:auto;
     }
} */
