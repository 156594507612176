/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */
.step-label-span {
  font-size: 19px;
  font-weight: 500;
  font-family: "Roboto', sans-serif";
}

.first-step-one {
  margin: auto;
  display: flex;
  width: 100%;
  gap: 20px;
}

.first-text-field {
  width: 598px;
}

.first-step-two {
  margin: auto;
  display: flex;
  width: 100%;
  gap: 20px;
}

.first-step-three {
  margin: auto;
  display: flex;
  width: 100%;
  gap: 20px;
}
.create-assessment-next-step-btn {
  padding: 0.7em;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  border-radius: 15px;
  width: 20%;
  color: #ffff;
  font-weight: 500;
}
.second-step-list-items {
  list-style: none;
}
.item-container {
  margin-top: 2rem;
}
.second-step-next-button {
  padding: 0.7em;
  border-radius: 10px;
  margin-bottom: 50px;
  color: #fff;
}
.link-style {
  text-decoration: none;
}
.previous-step-next-button {
  padding: 0.7em;
  border-radius: 10px;
  color: #ffff;
}
.error-message-create-assessment {
  color: red;
  font-size: 12px;
  margin-left: 40px;
  font-weight: 500;
}
.third-tep-buttons {
  margin-top: 4em;
  justify-content: center;
  display: flex;
  gap: 2em;
  padding-right: 16px;
  padding-left: 16px;
}
.custom-questions-heading {
  display: flex;
  justify-content: center;
  font-size: 19px;
  margin-top: 20px;
}
.error-message {
  color: red;
}

.add-question-answer-section {
  float: right;
  margin-left: auto;
  margin-right: 5em;
  margin-top: -32em;
}

.add-question-heading {
  font-size: 30px;
}
.create-assessment-stepper {
  margin-top: 30px;
}
.selected-option-menu {
  width: 100%;
  margin-top: -8px;
}
.drop-down-menu-main {
  margin-top: -20px;
  width: 100%;
}
.second-step-main-container h4 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.button-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}
.partition {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #9c9a9a;
  line-height: 0.1em;
  margin: 30px 0 30px;
}
.partition span {
  background: #fff;
  padding: 0 10px;
}

@media (max-width: 730px) {
  .add-question-answer-section {
    float: none;
  }
  .third-tep-buttons {
    gap: 1em;
  }
  .button-image-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }
}

/* .second-step-list-items {
  list-style: none;
}
.item-container {
  margin-top: 2rem;
}
.second-step-next-button {
  padding: 0.7em;
  border-radius: 10px;
  margin-bottom: 50px;
  color: #ffff;
}
.link-style {
  text-decoration: none;
}
.previous-step-next-button {
  padding: 0.7em;
  border-radius: 10px;
  color: #ffff;
}
.card:active {
  background-color: #1f7ebc;
}

.custom-questions-heading {
  display: flex;
  justify-content: center;
  font-size: 19px;
  margin-top: 20px;
}
.error-message {
  color: red;
}

.add-question-answer-section {
  float: right;
  margin-left: auto;
  margin-right: 5em;
  margin-top: -32em;
}

.add-question-heading {
  font-size: 30px;
}
.create-assessment-stepper {
  margin-top: 30px;
}
.selected-option-menu {
  width: 100%;
}
.drop-down-menu-main {
  width: 100%;
}
.second-step-main-container h4 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.button-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}
.partition {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #9c9a9a;
  line-height: 0.1em;
  margin: 30px 0 30px;
}
.partition span {
  background: #fff;
  padding: 0 10px;
}

@media (max-width: 730px) {
  .add-question-answer-section {
    float: none;
  }

  .button-image-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }
} */
