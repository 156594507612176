.organization-dashboard {
  width: 100%;
  /* height: 30%; */
  margin-top: 90px;
  display: flex;
  text-align: center;
}
.create-admin-submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
}
.org-dashboard-add-admin-btn {
  border-radius: 10px;
  padding: 0.5em;
  font-size: 15px;
  font-weight: 400;
  color: #ffff;
}
.user-container {
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-end;
}
.plan-drop-down {
  margin-bottom: 20px;
}
.swal2-styled.swal2-confirm {
  background-color: #1f7ebc;
}

/* .swal2-icon{
  border: 1px solid #1f7ebc;
} */

.swal2-icon-content {
  color: #1f7ebc;
}

.swal2-icon.swal2-warning {
  border: 3px solid #1f7ebc;
}

.swal2-icon.swal2-success {
  border-color: #1f7ebc;
}

/* .swal2-icon.swal2-success::before, .swal2-icon.swal2-success::after {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  outline: 1px solid #1f7ebc !important;
  
} */

.swal2-success-ring {
  border-color: #1f7ebc;
}

.swal2-success-fix {
  background-color: #1f7ebc;
}

.swal2-success-circular-line-right {
  color: #1f7ebc;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  display: block;
  position: absolute;
  z-index: 2;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #1f7ebc;
}

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid #99cdf0;
  border-radius: 50%;
}

.add-organization-page-container {
  display: flex;
  column-gap: 20px !important;
  /* flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: -10px;
  margin-bottom: 20px; */
}
.add-org-left {
  width: 400px !important;
}

.add-org-down {
  width: 310px !important;
}

.add-org-last {
  width: 270px !important;
}

.add-Organization-page {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 18px;
  padding-left: 32px;
  padding-right: 32px;
  height: 570px;
}

.form-user-container {
  background-color: #fff;
  padding: 3em;
  padding-bottom: 3em;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  /* box-shadow: 0 10px 40px -14px rgba(0, 0, 0, 0.25); */
}
.form-user-container .form-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.user-contact-details {
  margin-top: 4px;
  gap: 2em;
  margin-bottom: 15px;
  display: flex;
}

.country-code-text {
  display: flex;
  width: 60%;
}

.validation-error {
  font-size: 12px;
  color: red;
  /* margin-top: -20px; */
  /* word-break: break-all; */
}
.error-message {
  font-weight: 400;
  font-size: 16px;
}
.sign-up-button {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.Other-Validation-Error {
  /* margin-top: 150px; */
  font-size: 12px;
  color: red;
  margin-top: -35px;
  margin-left: 52%;
}

.display-candidate-thead th {
  background-color: #1f7ebc;
  color: white;
}
/* 
.display-candidate-tr:hover {
  background-color: #f3f3f3;
  color: black;
} */

/* .col{
  border:1px solid black !important;
} */

.table-parent-div {
  /* padding: 12px; */

  height: 60vh !important;

  width: 100%;

  overflow-y: scroll;

  overflow-x: hidden;
}
.display-candidate-thead {
  background-color: #1f7ebc;
  color: white;
  position: absolute;
  top: 0px;
  border: 1px solid;
  z-index: 1;
}

.table-display-candidate {
  color: white;
  text-align: center;
  position: relative;
}

.table-display-candidate tbody {
  text-align: center !important;
}

.ContactError {
  margin-top: -4%;
  font-size: 12px;
  color: red;
}
.OtherErrors {
  margin-left: 52%;
  margin-top: -35px;
  font-size: 12px;
  color: red;
}
.Plan-error {
  font-size: 12px;
  color: red;
  margin-top: -5%;
}
.add-admin-parent {
  display: flex;
  column-gap: 20px !important;
}
.add-admin-left {
  margin-left: 20px;
  width: 280px;
}
.add-admin-right {
  margin-right: 20px;
}
.create-admin-page {
  padding-top: 20px !important;

}
