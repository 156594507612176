#root {
  height: 100vh;
}
.home-test-taker {
  padding-top: 2em;
}
.status-images {
  margin: auto;
  width: 400px;
  margin-bottom: 30px;
  text-align: center;
}
.go-back-btn {
  width: 20%;
  border-radius: 16px;
  padding: 0.5em;
  margin-top: 20px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  
}
.home-page-button-completed-status{
  width: 20%;
  border-radius: 16px;
  padding: 0.5em;
  margin-top: 20px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  background-color: #18bff7;
}
.test-taker-home-container {
  background-color: #ffffff;
  padding: 3em;
  margin-top: 30px;
  border-radius: 8px;
  justify-content: center;
  box-shadow: 10px 10px 40px -14px rgba(0, 0, 0, 0.25);
}
.test-error-message{
  margin-top: 40px;
  font-size: 28px;
  font-weight: 500;
}
.button-test-taker {
  display: flex;
  width: 20%;
  padding: 0.5em;
  border-radius: 50px;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  justify-content: center;
}
.test-instruction-btn{
  width: 30%;
}
.button-capture-image {
  width: 50%;
  color: #ffff;
  font-weight: 400;
  /* margin: 36px; */
  margin: 10px 0px 20px 48px;
}
.candidate-test-start-button {
  width: 40%;
}
.continue-camera {
  margin-top: 20px;
}
.welcome-text {
  font-size: 22px;
  color: #191919;
  margin: 1rem 0rem;
}
.welcome-modal-text {
  font-size: 15px;
  color: #191919;
  margin: 1rem 0rem;
  width: 750px;
  text-align: justify;
}
.test-taker-heading {
  font-size: 35px;
}
.welcome-test-taker {
  text-align: left;
}
.display-candidate-thead th {
  background-color: #1f7ebc;
  color: white;
}

/* .display-candidate-tr:hover {
  background-color: #f3f3f3;
  color: black;
} */

/* .col{
  border:1px solid black !important;
} */

.table-parent-div {
  /* padding: 12px; */

  height: 60vh !important;

  width: 100%;

  overflow-y: scroll;

  overflow-x: hidden;
}
.display-candidate-thead {
  background-color: #1f7ebc;
  color: white;
  position: absolute;
  top: 0px;
  border: 1px solid;
  z-index: 1;
}

.table-display-candidate {
  color: white;
  text-align: center;
  position: relative;
}

.table-display-candidate tbody{
  text-align: center !important;
}

/* .text-camera-model{
  height:100vh;
  border: 1px solid;
} */

@media (max-width: 630px) {
}
