@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.checkmark{
    margin-left: -50px;
    margin-top: 30px;
    background-color:  rgb(173, 170, 167); ;
  }
  .second-step-list-items {
    list-style: none;
  }
  .item-container {
    margin-top: 4rem;
  }
  .second-step-next-button {
    margin-bottom: 50px;
   
  }

  .swal-deny-button {
    /* margin-bottom: 50px; */
    background-color: aquamarine;
    font-family: "Roboto", sans-serif;
    font-size: 14;
   
  }
  
  .third-tep-buttons {
    margin-top: 2em;
    justify-content: space-between;
    display: flex;
    gap: 2em;
  }
  .error-message {
    color: red;
  }
  .create-assessment-next-button {
    margin-top: 20px;
    margin-left: auto;
    padding: 0.7em;
    margin-right: auto;
    color: #ffff;
    /* display: flex; */
    justify-content: center;
    margin-bottom: 50px;
  }
  .third-step-enter-question {
    /* width: 100%; */
    margin-right: 10px;
    height: 360px !important;
    justify-content: center;
    /* background-color: rgb(173, 170, 167); */
    text-decoration-color: white;
    border-color:rgb(173, 170, 167);
    border-width: 2px;
    border-style: solid;
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
  }
  .add-question-answer-section {
    float: right;
    margin-left: auto;
    margin-right: -3em;
    margin-top: -34.3em;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
  }

  .add-question-heading {
    margin-top: 80px;
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
  }

  .add-question-info-heading {
    margin-top: 20px;
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
  }
  .create-assessment-stepper {
    margin-top: 30px;
  }

  .container-radio .checkmark {
    position: absolute;
    top: 15px;
    left: 0;
    height: 15px;
    width: 15px;
    /* background-color: #d12a2a; */
    border-radius: 50%;
    z-index: 900;
  }
  
  .input-option-btn{
   
    height: 25px;
    background-color: red;
  
  }
  .thirdstep-dropdown{
    flex:1
  }
  .selected-option-menu .thirdstep-dropdown{
  text-decoration-color: white;
  background-color: #1f7ebc;
  }
  
  .third-step-main{
    flex-direction: column;
    margin-top: 20px;
   
  }
  
  .add-question-box{
    display: block;
    margin-top: -50px;
  }
  
  .third-step-enter-answer {
    resize: none;
    padding-top: 2px;
    padding-bottom: 2px;
    border-color:rgb(173, 170, 167);
    border-width: 1px;
    border-style: solid;
  }

  .option-box{
    display: flex;
    flex-direction: column;
    width: 50vw;
    margin: 0px 20px;
  }
  
  /* .add-question-answer-section{
    width: fit-content;
    position: relative;
  } */
  
  .checkBox{
    margin-bottom: 10px;
    margin-top: 100px;
    background-color: #d12a2a;
  }
  .newCheckBox{
    margin-right: -60px;
    position: absolute;
  }
  
  @media (max-width: 730px) {
    /* .third-step-enter-question {
      height: 230px;
      width: 90%;
    } */
 
    .add-question-answer-section {
      float: none;
    }
   
  }
  