.modal-background {
  backdrop-filter: blur(1.7px);
  height: 100%;
  width: 100vw;
  z-index: 10;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-layout {
  z-index: 11;
  background-color: white;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.modal-layout.flexible {
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
  border-radius: 0px;
}

.modal-layout > .modal-child-container {
  /* height: 100%; */
}

.modal-layout.flexible > .modal-child-container {
  overflow-y: auto;
  /* height: 100%; */
}

.modal-layout.modal-header > .modal-child-container {
  height: calc(100% - var(--navbarHeaderHeight));
}

.background-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #c4c4c4;
  background-color: #1f7ebc;
  height: 45px;
}
.x-button {
  cursor: pointer;
  margin: 5px;
  color: white;
}
.header {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

@media only screen and (min-width: 500px) {
  .modal-layout {
    max-width: 70%;
    /* max-height: 80%; */
    border-radius: 10px;
  }
  .modal-layout.flexible {
    border-radius: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .modal-layout {
    max-width: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  .modal-layout {
    max-width: 40%;
  }
}

@media only screen and (max-height: 475px) {
  .modal-layout {
    max-height: 100%;
  }
}
