@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* .input { */
  /* color: #384047; */
  /* border: none; */
  /* background-color: #f3f3f3;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  border-style: solid;
  border-color: gray;
  border-radius: 4px;
  padding: 1em;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
} */

.input {
  border-radius: 4px;
  padding: 1em;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid rgb(150, 146, 146);
}

.input:focus {
  outline: none;
}

.label-text-filed {
  /* font-family: "Roboto", sans-serif; */
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  font-size: 15px;
}
.required-text {
  color: red;
  font: "poppins";
}
