@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* chetan patil [13-4-23] stylish gig Added in Work Section */
.gif-div {
  margin: auto;
  margin-top: 80px;
  width: 90%;
  height: 300px;

  /* border:1px solid red; */
}

.gif {
  margin: auto;
  /* margin-left: 200px; */
  width: 100%;
  height: 250px;
  border-radius: 15px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.secondary-buttons {
  color: #ffff;
  padding: 1em;
  border-radius: 90px;
}
/* chetan patil [13-4-23] Online EXam Type Image Added in Home Page */
.front-image {
  margin: "auto";
  margin-left: "100px";
  width: "700px";
  height: "500px";
}
.front-image:hover {
  transform: scaleX(-1);
}
.front-image-section {
  margin: "auto";
  width: "50%";
  height: "500px";
  margin-right: "500px";
  margin-top: "3000px";
  /* border: 1px solid red; */
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -121px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 800px;
  flex: 1;
}
.home-image-banner {
  display: block;
  margin-left: auto;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: 40px;
  color: #4c4c4c;
  padding-top: 4rem;
  /* max-width: 600px; */
}
.primary-text {
  font-size: 20px;

  color: #6a6a6a;
  margin: 1.5rem 0rem;
  font-family: "Roboto", sans-serif;
  /* font-weight: 600; */
  letter-spacing: 0.2px;
}

.about-section-container {
  margin-top: 5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #1f7ebc;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  padding: 1em;
  border: none;
  border-radius: 90px;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}
.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  margin-top: 10rem;
  width: 100%;
  /* border:1px solid red; */
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}
/* .footer-section-one {
    border: 2px solid blue;
  } */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.testimonial-section-bottom img {
  /* border: 1px solid ; */
  width: 50% !important;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #1f7ebc;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 7rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}
/* .Float{
background-color: #adb1b4;
} */
.FloatLabel-email {
  display: flex;
  gap: 10px;
}

.demo-container {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  border-width: 20px;
  margin-top: 120px;
  padding: 40px;
}

.FloatLabel-names {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.email {
  margin-bottom: 20px;
}

.input-custom-width {
  width: 50%;
}
.demo-Next-btn {
  width: 80px;
  height: 40px;
  color: white;
}

.Thankyou-content {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  height: 400px;
  margin-top: -40px;
  /* margin-top: 50px; */
  /* outline:1px solid ; */
}
.wrapper-1 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 200px;
}
.wrapper-2 {
  padding: 30px;
  text-align: center;
  margin-top: 50px;
}
.wrapper-2 p {
  margin: 0;
  font-size: 1.3em;
  color: #aaa;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 1px;
}
.Thank-you-heading {
  /* font-family: 'Kaushan Script', cursive; */
  font-size: 4em;
  letter-spacing: 3px;
  color: #1f7ebc;
  margin: 0;
  margin-bottom: 20px;
}
.Heading-name {
  color: #1f7ebc;
  padding-top: 30px;
}
.demo-goHome-btn {
  width: 100px;
  height: 40px;
  color: white;
  margin-top: 10px;
}
.error-message {
  color: red;
}

@media (max-width: 1000px) {
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
.upgrade-container {
  /* background-color: #1f7ebc; */
  display: flex;
  justify-content: space-evenly;
  margin-left: -7.5vw;
  margin-right: -7.5vw;
  height: 100vh;
  align-items: center;
}
.pricing-colum {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  width: 330px;
  text-align: center;
  background-color: white;

  height: 500px;
  border-radius: 10px;

  margin-bottom: 20px;
}

.pp {
  padding: 24px 0;
  margin: 0;
}

.price-symbols {
  display: flex;
  width: 80vw;
  margin-left: 70px;
}

.icons {
  display: flex;
  gap: 10px;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  margin-top: -2px;
  margin-left: 20px;
  color: white;
}

.pricing-icons {
  background-color: #1f7ebc;
  border-radius: 50%;
  width: 30px;
  padding-bottom: 6px;
  justify-content: center;
}
.pricing-icons:hover {
  box-shadow: 0px 6px 10px rgba(243, 239, 239, 0.3);
  transform: translateY(-2px);
}
.pricing-icons:active {
  color: black;
}

.column-heading {
  flex-direction: column;
  justify-content: center;
}
.column-content-1 {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  gap: 15px;
  padding-top: 15px;
  align-content: center;
}
.column-content-2 {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  gap: 15px;
  justify-content: center;
}
.column-content-3 {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  gap: 15px;
  justify-content: center;
}
.content {
  flex-direction: column;
}
.subscribe-btn {
  width: 150px;
  height: 40px;
  color: white;
  margin-top: 27px;
  font-size: 18px;
  border-radius: 15px;
}
.check-uncheck {
  color: #1f7ebc;
  font-size: 10px;
}

@media (max-width: 800px) {
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  /* .primary-text {
    text-align: center;
    max-width: 80%;
  } */
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .home-image-banner {
    display: none;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    max-width: 95vw;
  }
  .FloatLabel-email {
    display: block;
    gap: 10px;
    margin: auto;
    width: 100%;
  }
  .FloatLabel-names {
    display: block;
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
  .input-custom-width {
    width: 80%;
    margin: auto;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .home-image-banner,
  .about-background-image-container {
    display: none;
  }

  .demo-container {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border-radius: 20px;
    border-width: 20px;
    padding: 40px 0;
    box-shadow: none;
  }
  .action-btn {
    display: block;
    width: fit-content;
    margin: auto;
  }
}

.my-button {
  background-color: rgba(171, 175, 177, 0);
}

.action-btn {
  display: flex;
  justify-content: left;
}

@media screen and (max-width: 1000px) {
  .upgrade-container {
    flex-direction: column;
    height: auto;
    padding: 100px;
    background-color: white;
  }
  .column-content-1 {
    position: relative;
  }
}
@media screen and (max-width: 400px) {
}

@media screen and (min-width: 1001px) and (max-width: 1123px){

   .navbar-links-container{
    margin: auto;
    width: fit-content;
   
    display: flex;
    flex-wrap: nowrap;
    gap: -10px !important;
    /* font-size: 60px !important; */
    /* font-weight: 13px; */
  } 
  .nav-pages-section{
    margin-right: 20px !important;
  } 
  .nav-last-section{
    margin-left: -30px !important;
    /* border: 1px solid red; */
  }
  .nav-underline-container{
    column-gap: 50px;
  }
}
