nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* overflow: hidden;
  position: fixed;
  top: 0; 
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
}
/* chetan patil [13-4-23] added underline to navbar */
/* nav a:hover {
  border-bottom: 3px solid black;
} */
/* chetan patil [13-4-23] added underline to navbar */
/* nav a:active {
  border-bottom: 3px solid black;
} */

.nav-pages-section:hover {
  border-bottom: 3px solid black;
}

.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: #141414;
  font-size: 21px;
}
.logout-navbar-option {
  color: #484848;
  cursor: pointer;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.primary-button:hover {
  color: #1f7ebc;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.hiring-logo {
  color: #1f7ebc;
  cursor: pointer;
  font-size: 30px;
  margin-top: 12px;
}
.logout-navbar-option :hover {
  border-bottom: 0px;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
}
