@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.item-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3em;
  cursor: pointer;
  transition: background-color 10s ease-out 100ms;
  margin-bottom: 20px;
}
.item-container-bravens {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3em;
  cursor: pointer;
  transition: background-color 10s ease-out 100ms;
}
.Test-views {
  margin-top: -20px;
}
.create-assessment-button-ap {
  background: #173380;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: fixed;
  bottom: 0%; /* Adjust as needed */
  right: 0;
  margin-right: 20px;
  transform: translateY(-50%);
}
.create-assessment-plus-icon {
  color: white;
  /* font-weight: bold; */
  /* width: 90%; */
  display: flex;
  justify-content: center;
}
.Partition-line {
  display: flex;
  flex-direction: row;
  font-style: italic;
  margin-bottom: 20px;
  color: grey;
}

.Partition-line:before,
.Partition-line:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid grey;
  margin: auto;
}

.display-created-assessment {
  margin-top: 5em;
}
.test-details-submit-button {
  color: #ffff;
  margin-left: 40%;
}
.created-assessment-heading {
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 35px;
}
.select-test-button {
  margin-top: 10px;
  padding: 2em;
  border-radius: 10px;
  color: #ffff;
  padding: 0.5em;
  font-weight: 400;
}
.test-question-model-details {
  display: "flex";
  flex-direction: "column";
  padding: 32px;
}
.test-question-details-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.test-question-details-label {
  display: flex;
  flex-direction: row;
  flex: 1px;
}
.organization-test-question-heading {
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  opacity: 0.5;
  text-decoration: underline;
}
.test-details {
  display: flex;
  font-size: 20px;
}
.card:hover .eye-icon {
  opacity: 1;
}
.eye-icon {
  opacity: 0.1;
}
.exam-details-label-text {
  margin-right: 10px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.exam-details-value-text {
  margin-right: 10px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}
.org-test-question-send-test-btn {
  width: 200px;
  height: 55px;
  color: #ffff;
}

.test-question-back-btn {
  width: 200px;
  height: 55px;
  color: #ffff;
  float: right;
}
.org-test-question-cancel {
  width: 200px;
  height: 55px;
  color: #ffff;
  float: right;
  margin-bottom: 30px;
  margin-right: 5px;
}
.org-test-question-done {
  width: 200px;
  height: 55px;
  color: #ffff;
  float: right;
  margin-bottom: 30px;
}
.org-test-question-send-test-cancel-btn {
  width: 200px;
  height: 55px;
  color: #ffff;
  background-color: #cf142b;
}
.org-details-test-button {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  margin-top: 50px;
}
.card {
  text-align: center;
  width: 350px;
  border-radius: 5px;
  padding: 1.5em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.Test-Dropdown {
  width: 40%;
  font-size: 20px;
}
.P-tag-view-Test {
  margin-left: 25%;
  font-style: italic;
  font-size: 20px;
}
.Question-row {
  width: 5px;
  background-color: #cf142b;
}

.display-candidate-thead {
  background-color: #1f7ebc;
  color: white;
  position: sticky;
  text-align: center;
}

.display-candidate-thead th {
  background-color: #1f7ebc;
  color: white;
}

/* .display-candidate-tr:hover {
  background-color: #f3f3f3;
  color: black;
} */

/* .col{
    border:1px solid black !important;
  } */

.table-parent-div {
  /* padding: 12px; */

  height: 72vh;

  width: 100%;

  overflow-y: scroll;

  overflow-x: hidden;
}

.table-display-candidate {
  position: relative;
}

thead {
  background-color: #1f7ebc;
  color: white;
  position: absolute;
  top: 0px;
  border: 1px solid;
  z-index: 1;
}

.table-display-candidate tbody {
  text-align: justify;
}
