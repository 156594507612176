@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.scroll_bar::-webkit-scrollbar {
  width: 2px;
}

.scroll_bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
}

.scroll_bar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.dflt-cursr::-webkit-scrollbar {
  width: 2px;
}

.dflt-cursr::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
}

.dflt-cursr::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid #163380;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* outline: 1px solid limegreen !important; */
  /* background: rgb(0 100 0/0.1) !important; */
}
body {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
