.code-editor-container {
  /* margin-top: 1%; */
  /* border: 1px solid ; */
  display: flex;
  height: 100vh;
  /* padding: 1rem; */
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  scroll-behavior: smooth;
}
.code-quiz-countdown {
  background-color: #1f7ebc;
  border-radius: 0 0 1rem 1rem;
  margin: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  height: 4rem;
  padding: 1rem;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
}
.code-quiz-countdown h5 {
  margin: 0 auto;
  text-align: center;
  color: #f4f8fe;
  font-weight: 500;
  font-size: x-large;
  font-weight: 400;
}
.submit-assessment-button {
  background-color: rgb(255, 85, 0);
  margin-left: auto;
  padding: 0.25rem;
  /* justify-content: flex-end; */
  color: white;
}
.question-type-easy {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: auto;
  width: 7rem;
  border-radius: 5px;  
  background-color: rgb(41, 134, 41);
  color: #f4f8fe;
  text-align: center;
  text-transform: lowercase;
  text-transform: capitalize;
  font-weight: 700;
}
.question-type-moderate {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: auto;
  width: 7rem;
  border-radius: 5px;  
  background-color: rgb(243, 203, 26);
  color: #f4f8fe;
  text-align: center;
  text-transform: lowercase;
  text-transform: capitalize;
  font-weight: 700;
}
.question-type-hard {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: auto;
  width: 7rem;
  border-radius: 5px;  
  background-color: rgb(209, 40, 18);
  color:#f4f8fe;
  text-align: center;
  text-transform: lowercase;
  text-transform: capitalize;
  font-weight: 700;
}
.question-type-challenging {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: auto;
  width: 7rem;
  border-radius: 5px;  
  background-color: rgb(113, 13, 153);
  color:#f4f8fe;
  text-align: center;
  text-transform: lowercase;
  text-transform: capitalize;
  font-weight: 700;
}
.sidebar {
  border-radius: 1rem 0 0 1rem;
  background: #f8f7fe;
  display: flex;
  position: relative;
  overflow: hidden;
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
}
.main-content {
  flex: 1;
  padding: 20px;
  border-radius: 1rem;
}
.sidebar-content {
  height: 100%;
}
.heavy-text {
  font-weight: 500;
}
.question-header {
  padding: 1rem;
  position: relative;
  text-align: left;
}
.current-question-div {
  margin-left: 8px;
}
.current-question-info {
  font-size: 1.2rem;
}
.resize-bar {
  background: gray;
  cursor: ew-resize;
  width: 10px;
  height: 100%;
  position: absolute;
  border-radius: 2px;
  top: 0;
  right: -4px;
  z-index: 1;
}
.sample-input-output-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  /* padding: 0.1rem; */
  background-color: #f4f8fe;
  margin: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.code-editor-toolbar{
  display: flex;
  flex-direction: row;
}
.code-editor-language{
  margin-left: 1rem;
  box-shadow: rgba(27, 31, 35, 0.15);
}
.output-details {
  margin: auto;
}
.custom-input {
  border-color: black;
  border-width: 1px;
  width: 100%;
  height: 60px;
  /* display: none; */
}
.custom-input-div {
  align-items: center;
  text-align: center;
  /* display: none; */
  width: 100%;
}
.code-editor-theme{
  width: 50%;
  box-shadow: rgba(17, 17, 26, 0.05);
  border-color: hsl(0, 0%, 80%);
}
.code-editor-output-window {
  margin: auto;
  height: 20%;
  width: 100%;
  background-color: #f4f8fe;
  font-size: 15px;
  color: black;
}
.code-editor-output-window-error {
  width: 98%;
  height: 30%;
  color: rgb(211, 32, 32);
}
.code-editor-compile-status {
  color: black;
  font-size: 16px;
}

.compile-testcases {
  padding: .2rem;
  font-size: 1.2rem;
  width: fit-content;
  background-color: #89bafe;
  color: white;

}
.submit-button {
  padding: .2rem;
  font-size: 1.2rem;
  width: fit-content;
  background-color: green;
  color: white;
}
.compile-button{
  padding: .2rem;
  width: 35%;
  font-size: 1.2rem;
  color: white;
}
@media screen and (max-width:1300px){
  .compile-button{
    padding: .1rem;
    font-size: 1rem;
  }
  .compile-testcases{
    padding: .2rem;
    font-size: 1.1rem;
  }
  .submit-button{
    padding: .2rem;
    font-size: 1.1rem;
  }
}
.question-button{
  padding: .25rem;
  width: 200px;
  margin-top: 10px;
  margin-left: 1rem;
  color: white;
}
.code-editor-single-tab{
  margin: .5rem; 
}