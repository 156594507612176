.question-container {
  margin-top: 10px;
  background-color: #ffffff;
  padding: 3em;
  border-radius: 8px;
  justify-content: center;
  height: 100vh;
  /* box-shadow: 10px 10px 40px -14px rgba(102, 60, 60, 0.25); */
  /* width: 100%; */
}

.quiz-screen-ul {
  list-style: none;
}

/* body {
  overflow: hidden;
} */
.question-list {
  font-size: clamp(1rem, 3vw, 1.5rem);
  margin: 0.8rem 1.5rem;
  /* margin-top: 5 */
  background-color: #f3f3f3;
  padding: 16px;
  border: 3px solid white;
  border-radius: 8px;
  font-size: 20px;
  width: 90%;
}
.full-screen-enabled {
  background: #fff;
}
.question-list:hover {
  background-color: #1f7ebc;
  color: black;
}
.question-list_change {
  font-size: clamp(1rem, 3vw, 1.5rem);
  margin: 1.5rem 0rem;
  margin-top: 18px;
  padding: 16px;
  border: 3px solid white;
  border-radius: 8px;
  font-size: 20px;
  background-color: #1f7ebc;
  color: black;
}
.sample-question-title {
  padding-top: 10px;
  font-size: 20px;
}

.quiz-feedback-form {
  width: 100%;
  margin-bottom: 20px;
}

.question-text {
  color: #1f7ebc;
  font-size: 24px;
}
.partition {
  border-bottom: 1px solid #1f7ebc;
  line-height: 0.6em;
  margin: 30px 0 30px;
}

.quiz-countdown {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: -30px;
  margin-bottom: 20px;
}

.restart-button {
  background-color: #1f7ebc;
  border: none;
  color: white;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  border-radius: 24px;
}

.test-taker-next-btn {
  height: 50px;
  width: 200px;
  border-width: 0px;
  border-radius: 5px;
  background-color: #1f7ebc;
  color: #ffff;
  font-family: "Roboto";
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 40px;
  letter-spacing: 0.3px;
}

.code-Btn {
  margin-left: 1160px;
}

.container-radio {
  margin-right: 20px;
}
.check-div {
  width: 22px;
  height: 22px;
  background-color: white;
  border: 1px solid black;
  padding: 1px;
  border-radius: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-div div {
  background-color: black;
  border-radius: 50%;
  height: 14px;
  width: 14px;
}

.label-option {
  display: flex;
  flex-direction: "row";
  align-items: center;
}

.option-list {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.checkBox {
  margin-top: 47px;
  height: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
}

.check-box-input {
  width: 20px;
  height: 20px;
  background-color: #1f7ebc;
}

.updated-timer {
  margin-top: -15px;
}

.updated-timer h5 {
  margin-top: 33px;
  font-size: 17px;
}

.side-gif-main {
  /* border: 1px solid; */
  display: flex;
}

