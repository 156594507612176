@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  padding: 0;
  box-sizing: border-box;
}
/* To hide scrollbar */
*::-webkit-scrollbar {
  display: none;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #f6f6f6;
}

.dark {
  background-color: #333;
  color: #fff;
}
.light {
  background-color: #fff;
  color: #333;
}

.label {
  font-family: "Roboto", sans-serif;
}
