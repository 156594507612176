.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*   */
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}

.center {
  position: fixed;
  top: 50%;

  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
