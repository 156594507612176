.create-assessment-mobile-btn {
  display: none;
}
.heading {
  margin-top: 27px;
}
.nav-logo-container {
  text-decoration: none;
  pointer-events: none;
}
.rec-dashboard-send-assessment-btn {
  font-weight: 400;
  width: 80%;
  border-radius: 10px;
  background-color: #1f7ebc;
  border: none;
  color: #ffff;
  height: 40px;
}

.rec-dashboard-send-assessment-btn:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-4px);
}

.rec-dashboard-send-assessment-btn:active {
  background-color: rgba(66, 63, 63, 0.8);
}

.add-candidates-button {
  padding: 5px;
  background-color: #1f7ebc;
  outline: none;
  border: none;
  border-radius: 0.5rem;
  /* font-size: 1.1rem; */
  cursor: pointer;
  color: white;
  font-weight: 600;
  transition: 0.2s;
  margin-right: 17px;
}
.create-assessment-btn {
  padding: 0.6em;
  border-radius: 10px;
  font-weight: 400;
  font-size: 20px;
  color: #ffff;
}
.primary-texts {
  font-size: 40px;
  color: #6a6a6a;
  display: -webkit-inline-box;
}
.add-candidate-text {
  display: flex;
  flex: 1px;
  margin-left: 20px;
}
.add-candidate-submit-btn {
  color: #f8f4f4;
  width: 200px;
  height: 35px;
  border-radius: 10px;
  font-size: 16px;
}

.add-candidate-page {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 18px;
  padding-left: 32px;
  padding-right: 32px;
}
.add-candidate-page-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.first-step-main {
  margin-top: 5em;
}
.assessment-record {
  margin-top: 50px;
}
.button-create-assessment {
  float: right;
}
.display-candidate-thead th {
  background-color: #1f7ebc;
  color: white;
}

.InsideData{
  overflow-y: scroll;
  height: 350px;
  padding-top: -15px;
}

.display-candidate-thead {
  background-color: #1f7ebc;
  color: white;
  position: absolute;
  top: 0px;
  border: 1px solid;
  z-index: 1;
}

.table-display-candidate {
  color: white;
  text-align: center;
  position: relative;
}

.table-display-candidate tbody{
  text-align: center !important;
}

.add-candidate-parent{
  display: flex;
  column-gap: 30px;
}

.add-candidate-container{
  padding: 10px 20px 10px 20px !important;
}

.add-candidate-left{
  width: 270px;
}

.add-candidate-lastName{
  width: 230px;
}

@media (max-width: 969px) {
  .primary-texts {
    margin-top: 10px;
    font-size: 28px;
  }
}

@media (max-width: 630px) {
  .create-assessment-btn {
    display: none;
  }
  .create-assessment-mobile-btn {
    display: flex;
    float: right;
    padding: 12px;
  }
}
