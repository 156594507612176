.candidate-details {
  cursor: pointer;
}

.display-candidate-details-text {
  display: flex;

  flex: 1px;

  margin-left: 20px;
}


.candidates-details-option {
  padding: 5px;

  display: flex;

  margin-top: -3em;

  margin-bottom: 20px;

  margin-right: 10px;

  background-color: transparent;

  border-style: none;
}

.container-column-heading {
  font-weight: bold;
  margin-left: 10px;
  margin-top: 5px;
}
.container-column-body {
  margin-left: 10px;
  background-color: rgb(41, 134, 41);
  width: 100px;
  border-radius: 5px;
  color: white;
  text-align: center;
  text-transform: lowercase;
  text-transform: capitalize;
  font-weight: 700;
}
.container-column-aborted {
  margin-left: 10px;
  background-color: red;
  width: 100px;
  border-radius: 5px;
  color: white;
  text-align: center;
  text-transform: lowercase;
  text-transform: capitalize;
  font-weight: 700;
}

.button-candidates-details {
  display: flex;

  float: right;
}
.table-bordered {
  margin-top: 8px;
  border-collapse: collapse;
}

.table-bordered th,
.table-bordered td {
  font-size: medium;
}
/* .table-bordered tr:hover {
  background-color: #f5f5f5;
} */
.justify-content-end {
  justify-content: flex-end;
}
.table-bordered {
  margin-top: 8px;
  border-collapse: collapse;
}

.table-bordered,
.table-bordered td {
  font-size: medium;
}
.table-bordered .left-column:hover {
  background-color: #f5f5f5;
}
.justify-content-end {
  justify-content: flex-end;
}
.left-column {
  font-weight: 500;
}
.candidate-details-heading {
  font-size: 24px;
  margin-top: 1rem;
  background-color: #c0c0c0;
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.candidate-details-p {
  text-align: center;
  flex-grow: 1;
  margin: 0 auto;
  /* margin-left: 7rem; */
}

.assessment-record{
  height: 60vh !important;

  width: 100%;

  overflow-y: scroll;

  overflow-x: hidden;
}

@media screen and (max-width: 600px) {
  .candidate-details-p {
    font-size: 20px;
    text-align: center;
    flex-grow: 1;
    margin: 0 auto;
    margin-left: 2rem;
  }
}
.exam-report {
  font-size: 16px;
  background-color: #1f7ebc;
  border: none;
  border-radius: 0.5rem;
  /* padding: 0.5rem; */
  padding: 2px 7px 5px 7px;
  margin-left: auto;
  margin-right: 2rem;
  margin-bottom: 1rem;
  color: white;
}
.primary-texts{
  /* color: #1f7ebc; */
}
@media screen and (max-width: 600px) {
  .exam-report {
    font-size: 16px;
    background-color: #1f7ebc;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-left: auto;
    margin-right: 1rem;
  }
}
.candidate-details-home {
  margin-top: 20px;

  background-color: #fff;

  padding: 2.5em;

  padding-bottom: 2.5em;

  border-radius: 8px;

  display: flex;
  flex-wrap: wrap;

  flex-direction: column;

  justify-content: center;

  box-shadow: 0 10px 40px -14px rgba(0, 0, 0, 0.25);
}

.anti-cheating-monitor {
  font-size: 21px;
}

.anti-cheating-monitor-test-heading {
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.score-display {
  font-size: 18px;

  margin-top: -2.2em;

  float: right;
}
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  background-color: #f7f7f9;
  border-radius: 2rem;
  margin: 5px;
}
.container-1 {
  display: flex;
  padding: 0.5em;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.column-title {
  background-color: #c0c0c0;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  color: black;
  font-size: 20px;
  font-style: italic;
}
@media screen and (max-width: 1000px) {
  .container-1 {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 700px) {
  .container-1 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.container-2 {
  display: flex;
  padding: 0.5em;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}
.container-3 {
  display: flex;
  padding: 0.5em;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}
.column-chart {
  flex: 3;
  width: 33%;
  margin: 5px;
  border-radius: 1rem;
  box-shadow: 0 8px 8px -1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

/* .candidate-home{
  overflow-y: scroll;
} */

@media screen and (max-width: 1200px) {
  .container-2 {
    display: flex;
    flex-direction: column;
  }
  .column-chart {
    flex: 1;
    width: 100%;
  }
}
.column-score {
  flex: 33%;
  padding: 10px;
  margin: 5px;
  margin-top: 15px;
  float: right;
  border-radius: 1rem;
  box-shadow: 0 8px 8px -1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.invited-column {
  flex: 33%;
  padding: 10px;
  margin: 5px;
  margin-top: 15px;
  border-radius: 1rem;
  box-shadow: 0 8px 8px -1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.anti-cheating-column {
  flex: 33%;
  padding: 10px;
  margin: 5px;
  margin-top: 15px;
  border-radius: 1rem;
  box-shadow: 0 8px 8px -1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.carousel-column-images {
  max-width: 50%;
  margin: 5px;
  /* border: 1px solid; */
}
.carousel-column-videos {
  max-width: 50%;
  margin: 5px;
}

.carousel {
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 0.5rem;
}

.carousel .slide img {
  height: auto;
  margin: 0 auto;
}
.candidate-test-score {
  margin-right: -0.15em;
  float: right;
}

.candidate-test-score-assessment-details {
  float: right;
  margin-right: 16px;
}

.rating-message {
  margin-top: auto;
  margin-bottom: auto;
  padding: 2rem;

  font-size: 16px;
}

.rating-text-area {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.align-left {
  font-weight: bold;
  padding: 0.2rem;
  float: left;
}

.align-right {
  float: right;
  padding: 0.2rem;
  margin-right: 0.5rem;
}

.align-summary-left {
  float: left;
}

.align-summary-right {
  float: right;
}

.anti-flex-div {
  display: flex;
  justify-content: space-between;
  /* width: 325px; */
}

.exam-details-box {
  display: flex;
  justify-content: space-between;
}
