@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.drop-down-menu-main {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.dropdown_label_menu {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.selected-option-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px;
  width: auto;
  border-radius: 4px;
  background-color: #f3f3f3;
  cursor: pointer;
}
.selected-option-menu > img {
  margin-right: 10px;
}
.selected-option-menu-text {
  padding: 1px 0px;
  margin: 0px;
  font-size: 16px;
  color: #828282;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.hidden-drop-down-menu-list {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  width: 0%;
  max-height: 0px;
}

.drop-down-menu-list {
  top: 45px;
  background-color: #ffffff;
  z-index: 10;
  padding: 5px 10px;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 200px;
  box-shadow: 0px 0px 3px rgba(204, 204, 204, 0.8);
  position: absolute;
}

.drop-down-menu-list::-webkit-scrollbar {
  display: none;
}

.drop-down-menu-option {
  cursor: pointer;
  margin-top: 5px;
  padding: 5px;
  list-style-type: none;
  font-size: 14px;
  color: #828282;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.drop-down-menu-selected-option {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.drop-down-menu-option::-webkit-scrollbar {
  display: none;
}
.drop-down-menu-option:hover {
  color: var(--primaryBlue);
  background-color: #1f7ebc;
  font-weight: 600;
  transition-duration: 100ms ease-in;

  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

.icon-initial {
  transition: transform 110ms;
  transform: rotate(0deg);
}
.icon-menu-rotate {
  transition: transform 110ms;
  transform: rotate(180deg);
}

.edit-menu-icon {
  width: 12px;
  height: 12px;
}

.drop-down-menu-option-line {
  margin: 2px 1%;
  padding: 0;
  height: 1px;
  background-color: var(--lightGrey);
  opacity: 0.3;
}
